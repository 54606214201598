<template>
    <div class="testimonial-wrap pt-100 pb-75 bg-albastor">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
                    <div class="section-title style1 text-center mb-40">
                        <span>Our Testimonials</span>
                        <h2>What Client Says About Us</h2>
                    </div>
                </div>
            </div>
            <div class="testimonial-slider-one">
                <carousel
                    :autoplay="5000"
                    :settings='settings'
                    :breakpoints="breakpoints"
                >
                    <Slide v-for="slide in carouselItems" :key="slide.id">
                        <div class="testimonial-card style1">
                            <div class="client-info-area">
                                <div class="client-info-wrap">
                                    <div class="client-img">
                                        <img :src="slide.image" alt="Image">
                                    </div>
                                </div>
                                <div class="quote-icon">
                                    <i class="flaticon-quote"></i>
                                </div>
                            </div>
                            <p class="client-quote">{{slide.desc}}</p>
                            <div class="client-info">
                                <h3>{{slide.name}}</h3>
                                <span>{{slide.position}}</span>
                            </div>
                        </div>
                    </Slide>

                    <template #addons>
                        <navigation>
                            <template #next>
                                <i class='flaticon-next'></i>
                            </template>
                            <template #prev>
                                <i class='flaticon-left-arrow-1'></i>
                            </template>
                        </navigation>
                    </template>
                </carousel>
            </div>
        </div>
    </div> 
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'Testimonials',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                image: require('../../assets/images/testimonials/fullersoftware.jpg'),
                desc: "I discovered Forest Finance, and it fits the bill. Easy to work with, and I can see myself using them for years to come.",
                name: 'Cameron Fuller',
                position: 'Fuller Software LTD',
            },
            {
                id: 2,
                image: require('../../assets/images/testimonials/maxsharp.jpg'),
                desc: "Take's care of my bookkeeping, self assessment and CIS deductions giving me my time back to run my business.",
                name: 'Max Sharp',
                position: 'M Sharp Tiling',
            },
            {
                id: 2,
                image: require('../../assets/images/testimonials/arout-electrical.jpg'),
                desc: "The fact that it is cloud based has made it a great fit for the business. They are quick to respond and no question is too small.",
                name: 'Adam Rout',
                position: 'A Rout Electricals LTD',
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
			},
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
        },
    }),
})
</script>