<template>
    <div class="page-wrapper">
        <Navbar class="style1" />
        <MainBanner />
        <WhyChooseUs />
        <OnlineShopping />
        <Feature />
        <Testimonials />
        <Contact />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import MainBanner from '../HomeOne/MainBanner'
import Feature from '../HomeOne/Feature'
import WhyChooseUs from '../HomeOne/WhyChooseUs'
import Counter from '../HomeOne/Counter'
import Services from '../HomeOne/Services'
import OnlineShopping from '../HomeOne/OnlineShopping'
import Testimonials from '../HomeOne/Testimonials'
import Contact from '../Contact/Contact'
import Footer from '../Layouts/Footer'

export default {
    name: 'HomeOnePage',
    components: {
        Navbar,
        MainBanner,
        Feature,
        WhyChooseUs,
        Counter,
        Services,
        OnlineShopping,
        Testimonials,
        Footer,
        Contact
    }
}
</script>