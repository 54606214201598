<template>
    <div class="terms-wrap pt-100 pb-100">
        <div class="container">
            <div class="row gx-5">
                <p><strong>PRIVACY NOTICE</strong> issued by <em>Forest Finance LTD</em></p>
                <p><strong>Introduction</strong></p>
                <p>The Data Protection Act 2018 (&quot;DPA 2018&quot;) and the General Data Protection Regulation
                    (&quot;GDPR&quot;) impose certain legal obligations in connection with the processing of personal
                    data<em>.</em></p>
                <p><em>Forest Finance LTD</em> is a data controller within the meaning of the GDPR and we process
                    personal
                    data. The firm&#39;s contact details are as follows: info@forest-finance.co.uk</p>
                <p>We may amend this privacy notice from time to time. If we do so, we will supply you with and/or
                    otherwise
                    make available to you a copy of the amended privacy notice.</p>
                <p>Where we act as a data processor on behalf of a data controller (for example, when processing
                    payroll),
                    we provide an additional schedule setting out required information as part of that agreement. That
                    additional schedule should be read in conjunction with this privacy notice.</p>
                <p><strong>The purposes for which we intend to process personal data</strong></p>
                <p>We intend to process personal data for the following purposes<em>:</em></p>
                <ul>
                    <li>To enable us to supply professional services to you as our client.</li>
                    <li>To fulfil our obligations under relevant laws in force from time to time (e.g. the Money
                        Laundering,
                        Terrorist Financing and Transfer of Funds (Information on the Payer) Regulations 2017 as amended
                        (&quot;MLR 2017&quot;)).</li>
                    <li>To comply with professional obligations to which we are subject as a member of AAT.</li>
                    <li>To use in the investigation and/or defense of potential complaints, disciplinary proceedings and
                        legal proceedings.</li>
                    <li>To enable us to invoice you for our services and investigate/address any attendant fee disputes
                        that
                        may have arisen.</li>
                    <li>To contact you about other services we provide which may be of interest to you if you have
                        consented
                        to us doing so.</li>
                </ul>
                <p><strong>The legal bases for our intended processing of personal data</strong></p>
                <p>Our intended processing of personal data has the following legal bases:</p>
                <ul>
                    <li>At the time you instructed us to act, you gave consent to our processing your personal data for
                        the
                        purposes listed above.</li>
                    <li>The processing is necessary for the performance of our contract with you.</li>
                    <li>The processing is necessary for compliance with legal obligations to which we are subject (e.g.
                        MLR
                        2017).</li>
                    <li>The processing is necessary for the purposes of the following legitimate interests which we
                        pursue:
                        including but not limited to investigating/defending legal claims.</li>
                </ul>
                <p>It is a requirement of our contract with you that you provide us with the personal data that we
                    request.
                    If you do not provide the information that we request, we may not be able to provide professional
                    services to you. If this is the case, we will not be able to commence acting or will need to cease
                    to
                    act.</p>
                <p><strong>Persons/organisations to whom we may give personal data</strong></p>
                <p>We may share your personal data with:</p>
                <ul>
                    <li>HMRC</li>
                    <li>any third parties with whom you require or permit us to correspond</li>
                    <li>subcontractors</li>
                    <li>an alternate appointed by us in the event of incapacity or death</li>
                    <li>tax insurance providers</li>
                    <li>professional indemnity insurers</li>
                    <li>our professional body AAT and/or the Office of Professional Body Anti-Money Laundering
                        Supervisors
                        (OPBAS) in relation to practice assurance and/or the requirements of MLR 2017 (or any similar
                        legislation)</li>
                </ul>
                <p>If the law allows or requires us to do so, we may share your personal data with:</p>
                <ul>
                    <li>the police and law enforcement agencies</li>
                    <li>courts and tribunals</li>
                    <li>the Information Commissioner&#39;s Office (ICO)</li>
                </ul>
                <p>We may need to share your personal data with the third parties identified above in order to comply
                    with
                    our legal obligations, including our legal obligations to you. If you ask us not to share your
                    personal
                    data with such third parties we may need to cease to act.</p>
                <p><strong>Transfers of personal data outside the EEA</strong></p>
                <p>Your personal data will be processed in the EEA only<em>.</em></p>
                <p><strong>Retention of personal data</strong></p>
                <p>When acting as a data controller and in accordance with recognised good practice within the tax and
                    accountancy sector we will retain all of our records relating to you as follows:</p>
                <ul>
                    <li>where tax returns have been prepared it is our policy to retain information for 6 Years from the
                        end
                        of the tax year to which the information relates.</li>
                    <li>where ad hoc advisory work has been undertaken it is our policy to retain information for 3
                        years
                        from the date the business relationship ceased.</li>
                    <li>where we have an ongoing client relationship, data which is needed for more than one year&#39;s
                        tax
                        compliance (e.g. capital gains base costs and claims and elections submitted to HMRC) is
                        retained
                        throughout the period of the relationship, but will be deleted 3 years after the end of the
                        business
                        relationship unless you as our client ask us to retain it for a longer period.</li>
                </ul>
                <p>Our contractual terms provide for the destruction of documents after 3 years and therefore agreement
                    to
                    the contractual terms is taken as agreement to the retention of records for this period, and to
                    their
                    destruction thereafter.</p>
                <p>You are responsible for retaining information that we send to you (including details of capital gains
                    base costs and claims and elections submitted) and this will be supplied in the form agreed between
                    us.
                    Documents and records relevant to your tax affairs are required by law to be retained by you as
                    follows:
                </p>
                <p><em>Individuals, trustees and partnerships</em></p>
                <ul>
                    <li>with trading or rental income: five years and 10 months after the end of the tax year;</li>
                    <li>otherwise: 22 months after the end of the tax year.</li>
                </ul>
                <p><em>Companies, LLPs and other corporate entities</em></p>
                <ul>
                    <li>six years from the end of the accounting period.</li>
                </ul>
                <p>Where we act as a data processor as defined in DPA 2018, we will delete or return all personal data
                    to
                    the data controller as agreed with the controller at the termination of the contract.</p>
                <p><strong>Requesting personal data we hold about you (subject access requests)</strong></p>
                <p>You have a right to request access to your personal data that we hold. Such requests are known as
                    &#39;subject access requests&#39; (&quot;SARs&quot;).</p>
                <p>Please provide all SARs in writing marked for the attention of <em>Jake Bailey.</em></p>
                <p>To help us provide the information you want and deal with your request more quickly, you should
                    include
                    enough details to enable us to verify your identity and locate the relevant information. For
                    example,
                    you should tell us:</p>
                <ol>
                    <li>your date of birth</li>
                    <li>previous or other name(s) you have used</li>
                    <li>your previous addresses in the past five years</li>
                    <li>personal reference number(s) that we may have given you, for example your national insurance
                        number,
                        your tax reference number or your VAT registration number</li>
                    <li>what type of information you want to know</li>
                </ol>
                <p>If you do not have a national insurance number, you must send a copy of:</p>
                <ul>
                    <li>the back page of your passport or a copy of your driving licence; and</li>
                    <li>a recent utility bill.</li>
                </ul>
                <p>DPA 2018 requires that we comply with a SAR promptly and in any event within one month of receipt.
                    There
                    are, however, some circumstances in which the law allows us to refuse to provide access to personal
                    data
                    in response to a SAR (eg if you have previously made a similar request and there has been little or
                    no
                    change to the data since we complied with the original request).</p>
                <p>We will not charge you for dealing with a SAR.</p>
                <p>You can ask someone else to request information on your behalf – for example, a friend, relative or
                    solicitor. We must have your authority to respond to a SAR made on your behalf. You can provide such
                    authority by signing a letter which states that you authorise the person concerned to write to us
                    for
                    information about you, and/or receive our reply.</p>
                <p>Where you are a data controller and we act for you as a data processor (eg by processing payroll), we
                    will assist you with SARs on the same basis as is set out above.</p>
                <p><strong>Putting things right (the right to rectification)</strong></p>
                <p>You have a right to obtain the rectification of any inaccurate personal data concerning you that we
                    hold.
                    You also have a right to have any incomplete personal data that we hold about you completed. Should
                    you
                    become aware that any personal data that we hold about you is inaccurate and/or incomplete, please
                    inform us immediately so we can correct and/or complete it.</p>
                <p><strong>Deleting your records (the right to erasure)</strong></p>
                <p>In certain circumstances you have a right to have the personal data that we hold about you erased.
                    Further information is available on the ICO website (<a
                        href="http://www.ico.org.uk/">www.ico.org.uk</a>). If you would like your personal data to be
                    erased, please inform us immediately and we will consider your request. In certain circumstances we
                    have
                    the right to refuse to comply with a request for erasure. If applicable, we will supply you with the
                    reasons for refusing your request.</p>
                <p><strong>The right to restrict processing and the right to object</strong></p>
                <p>In certain circumstances you have the right to &#39;block&#39; or suppress the processing of personal
                    data or to object to the processing of that information. Further information is available on the ICO
                    website (<a href="http://www.ico.org.uk/">www.ico.org.uk</a>). Please inform us immediately if you
                    want
                    us to cease to process your information or you object to processing so that we can consider what
                    action,
                    if any, is appropriate.</p>
                <p><strong>Obtaining and reusing personal data (the right to data portability)</strong></p>
                <p>In certain circumstances you have the right to be provided with the personal data that we hold about
                    you
                    in a machine-readable format, e.g. so that the data can easily be provided to a new professional
                    adviser. Further information is available on the ICO website (<a
                        href="http://www.ico.org.uk/">www.ico.org.uk</a>).</p>
                <p>The right to data portability only applies:</p>
                <ul>
                    <li>to personal data an individual has provided to a controller;</li>
                    <li>where the processing is based on the individual&#39;s consent or for the performance of a
                        contract;
                        and</li>
                    <li>when processing is carried out by automated means</li>
                </ul>
                <p>We will respond to any data portability requests made to us without undue delay and within one month.
                    We
                    may extend the period by a further two months where the request is complex or a number of requests
                    are
                    received but we will inform you within one month of the receipt of the request and explain why the
                    extension is necessary.</p>
                <p><strong>Withdrawal of consent</strong></p>
                <p>Where you have consented to our processing of your personal data, you have the right to withdraw that
                    consent at any time. Please inform us immediately if you wish to withdraw your consent.</p>
                <p>Please note:</p>
                <ul>
                    <li>the withdrawal of consent does not affect the lawfulness of earlier processing</li>
                    <li>if you withdraw your consent, we may not be able to continue to provide services to you</li>
                    <li>even if you withdraw your consent, it may remain lawful for us to process your data on another
                        legal
                        basis (e.g. because we have a legal obligation to continue to process your data)</li>
                </ul>
                <p><strong>Automated decision-making</strong></p>
                <p>We do not intend to use automated decision-making in relation to your personal data.</p>
                <p><strong>Complaints</strong></p>
                <p>If you have requested details of the information we hold about you and you are not happy with our
                    response, or you think we have not complied with the GDPR or DPA 2018 in some other way, you can
                    complain to us. Please send any complaints to info@forest-finance.co.uk.</p>
                <p>If you are not happy with our response, you have a right to lodge a complaint with the ICO (<a
                        href="http://www.ico.org.uk/">www.ico.org.uk</a>).</p>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PrivacyPolicy'
}
</script>