<template>
    <div class="project-details-wrap pt-10 pb-100">
        <div class="container">
            <div class="row gx-5">
                <div class="col-xl-12 col-lg-12">
                    <div class="project-desc">
                        <img class="wh-shape-one bounce" src="../../assets/images/why-choose-us/wh-shape-1.png" alt="Image">
                        <div class="row justify-content-center">
                            <div class="col-lg-6 col-sm-12 align-self-center">
                                <img className="img-responsive p-20" src='../../assets/images/freeagent-logo.webp' />
                            </div>
                        </div>
                        <p>FreeAgent's powerful and easy-to-use accounting software for UK businesses brings everything
                            together - from invoices and expenses to Self Assessment tax returns, payroll and
                            MTD-compatible VAT filing.</p>
                        <h2>Features</h2>
                        <ul class="content-feature-list style3 list-style">
                            <li><i class="ri-check-line"></i><b>Your business at a glance</b> Check your cashflow,
                                profit and
                                loss, and invoice payments anytime, anywhere.</li>
                            <li><i class="ri-check-line"></i><b>Explain bank transactions</b> Link your bank account and
                                avoid
                                the hassle of exporting bank statement.</li>
                            <li><i class="ri-check-line"></i><b>Create expenses on the go</b> Just snap a photo of the
                                receipt
                                with your phone.</li>
                            <li><i class="ri-check-line"></i><b>Never miss a deadline</b> Receive notifications on your
                                phone
                                when tax is due.</li>
                            <li><i class="ri-check-line"></i><b>Raise invoices with a few taps</b> Turn estimates into
                                invoices
                                and set up automated chaser emails.</li>
                            <li><i class="ri-check-line"></i><b>Track your time Track</b> how long you spend on a job
                                and add
                                it to your invoice.</li>
                        </ul>


                        <h2>Award Winning Software!</h2>
                        <p>Voted the UK's #1 bookkeeping software for small businesses</p>
                        <p>Top Product: 2020 and 2021 AccountingWEB Software Awards, SME Accounting /
                            Bookkeeping Software, Data & Expense Management Software</p>
                        <p>Winner: 2021 ICB Luca awards, Client App of the Year</p>
                        <img class="wh-shape-two animationFramesTwo" src="../../assets/images/why-choose-us/wh-shape-2.png" alt="Image">
                        <div class="row p-3 mb-10">
                            <div class="col-md-3 col-xs-6">
                                <img class="img-responsive p-4"
                                    src='../../assets/images/luca-awards-2021--client-app-of-the-year.png' />
                            </div>
                            <div class="col-md-3 col-xs-6 p-4">
                                <img class="img-responsive"
                                    src='../../assets/images/luca-awards-2022--friendliest-software-of-the-year.webp' />
                            </div>
                            <div class="col-md-3 col-xs-6 p-4">
                                <img class="img-responsive"
                                    src='../../assets/images/accounting-web-software-awards-2021--top-product--data-and-expense-management.webp' />
                            </div>
                            <div class="col-md-3 col-xs-6 p-4">
                                <img class="img-responsive"
                                    src='../../assets/images/accounting-web-software-awards-2021--top-product--bookkeeping.webp' />
                            </div>
                        </div>


                        <h2>Get The App For Free</h2>
                        <div class="row gx-5 align-items-center mb-40">
                            <div class="col-lg-6">
                                <div class="wh-img-wrap">
                                    <img src='../../assets/images/phones-with-mobile-app.png' alt="Image">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="wh-content">
                                    <div class="feature-item-wrap">
                                        <div class="feature-item">
                                            <div class="feature-text">
                                                <p>The FreeAgent mobile app lets you check your business finances on the
                                                    go and makes it
                                                    quick
                                                    and easy for you to update them regularly, so we can give you the
                                                    best timely advice
                                                </p>
                                            </div>
                                        </div>
                                        <div class="feature-item">
                                            <div class="feature-text">
                                                <h4>1. Simple download the FreeAgent Mobile app.</h4>
                                            </div>
                                        </div>
                                        <div class="feature-item">
                                            <div class="feature-text">
                                                <h4>2. Log in using your email address and password.</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <img class="img-responsive" src='../../assets/images/app-store.png'
                                            style="height:75px;" />
                                    </div>
                                    <div class="col-md-6">
                                        <img class="img-responsive" src='../../assets/images/google-play_2x.png'
                                            style="height:75px;" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h2>Advanced Time-saving Tips</h2>
                        <ul class="mb-40">
                            <li>Log into FreeAgent on your laptop or desktop, to give your
                                FreeAgent mobile app a boost with these powerful account settings.</li>
                            <li>All your contacts secure in one place along with their
                                invoice and payments details
                                <a href="https://support.freeagent.com/hc/en-gb/articles/115001222904-Import-contacts">More
                                    on Contacts</a>
                            </li>
                            <li>FreeAgent integrates with <a
                                    href="https://support.freeagent.com/hc/en-gb/articles/115001218930-PayPal-Receiving-invoice-payments">PayPal</a>,
                                <a
                                    href="https://support.freeagent.com/hc/en-gb/articles/115001218850-GoCardless-setting-up-the-integration">GoCardless</a>
                                and <a
                                    href="https://support.freeagent.com/hc/en-gb/articles/115001223524-Stripe-Receiving-invoice-payments">Stripe</a>,
                                so
                                you can easily take online payments
                                <a href="https://support.freeagent.com/hc/en-gb/sections/115000533430-Online-payments">More
                                    on online payments</a>
                            </li>
                            <li>Make the most of templates Select from a gallery of
                                great-looking invoice templates and set up a price list for invoice items you quote for
                                regularly
                                <a href="https://support.freeagent.com/hc/en-gb/categories/115000400644-Invoicing">More
                                    on invoicing</a>
                            </li>
                        </ul>
                        <h2>Further Help & Support</h2>
                        <p>Head over to our Knowledge Base for helpful and practical guides to using FreeAgent.
                            Our UK-based support team are also on hand to help. 0800 288 8691 (Mon-Fri, 9am-5pm) or drop
                            an email to support@freeagent.com.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'FreeAgent',
    components: {
    },
    watch: {
        items() {
            this.$nextTick(() => {
                lightGallery.refresh();
            });
        },
    },

};
</script>
<style lang="css">
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.1.0-beta.1/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.1.0-beta.1/css/lg-zoom.css');
</style>