<template>
  <div :class="['header-wrap', { sticky: isSticky }]">
    <!-- Top Header -->
    <div class="header-top" :class="{ open: open }">
      <button type="button" class="close-sidebar" @click="open = !open">
        <i class="ri-close-fill"></i>
      </button>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-8 col-md-9">
            <div class="header-top-left">
              <ul class="contact-info list-style">
                <li>
                  <i class="flaticon-bubble-chat"></i>
                  <a href="sms:+447415383770">(+44) 7415 383 770</a>
                </li>
                <li>
                  <i class="flaticon-email-1"></i>
                  <a href="mailto:info@forest-finance.co.uk">info@forest-finance.co.uk</a>
                </li>
                <li>
                  <i class="flaticon-pin"></i>
                  <p>Harlow, Essex, UK</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Top Header -->

    <!-- Navbar -->
    <div class="header-bottom">
      <div class="container">
        <nav class="navbar navbar-expand-md navbar-light sticky-top">
          <router-link class="navbar-brand" to="/">
            <img class="logo-light d-inline-block align-top" src="../../assets/images/logo-title.png" alt="logo"
              width="240" />
          </router-link>

          <div class="collapse navbar-collapse" :class="{ show: active }">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <router-link to="/" class="nav-link">Home</router-link>
              </li>

              <!-- <li class="nav-item">
                <router-link to="/about" class="nav-link">About</router-link>
              </li>

              <li class="nav-item has-dropdown">
                <a href="#" class="nav-link">
                  Who We Help
                  <i class="ri-arrow-down-s-line"></i>
                </a>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <router-link to="/" class="nav-link">Start-ups</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/" class="nav-link">Limited Companies</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/" class="nav-link">Trades</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/" class="nav-link">Landlords</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/" class="nav-link">Individuals</router-link>
                  </li>
                </ul>
              </li> -->

<!-- 
              <li class="nav-item">
                <router-link to="/services" class="nav-link">Services</router-link>
              </li> -->


              <!-- <li class="nav-item">
                <router-link to="/about" class="nav-link">About</router-link>
              </li>

              <li class="nav-item has-dropdown">
                <router-link to="/services" class="nav-link">Services</router-link>
              </li> -->

              <li class="nav-item has-dropdown">
                <a href="#" class="nav-link">
                  Info
                  <i class="ri-arrow-down-s-line"></i>
                </a>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <router-link to="/freeagent" class="nav-link">FreeAgent</router-link>
                  </li>
                  <li class="nav-item">
                    <a href="https://login.freeagent.com/login/" class="nav-link">My FreeAgent</a>
                  </li>
                  <li class="nav-item">
                    <a href="https://myportal.forest-finance.co.uk/signin/" class="nav-link">My Portal</a>
                  </li>

                  <!-- <li class="nav-item">
                    <router-link to="/pricing" class="nav-link">Pricing</router-link>
                  </li> -->
                </ul>
              </li>

              <li class="nav-item">
                <router-link to="/contact" class="nav-link">Contact Us</router-link>
              </li>
            </ul>
          </div>

          <div class="mobile-bar-wrap">
            <div class="navbar-toggler mobile-menu xl-none" @click="active = !active"
              :aria-pressed="active ? 'true' : 'false'" v-bind:class="{ active: button_active_state }"
              v-on:click="button_active_state = !button_active_state">
              <a href="javascript:void(0);">
                <i class="ri-menu-line"></i>
                <i class="ri-close-line"></i>
              </a>
            </div>
          </div>
        </nav>
      </div>

      <!-- Search Modal -->
      <div class="search-area" :class="{ search: search }">
        <div class="container">
          <form @submit.prevent>
            <div class="form-group">
              <input type="search" placeholder="Search Here" autofocus />
            </div>
          </form>
          <button type="button" class="close-searchbox" @click="search = !search">
            <i class="ri-close-line"></i>
          </button>
        </div>
      </div>
    </div>
    <!-- End Navbar -->
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data: () => ({
    isSticky: false,
    active: false,
    button_active_state: false,
    search: false,
    button_search_state: false,
    open: false,
    button_open_state: false,
  }),
  mounted() {
    const that = this;
    window.addEventListener("scroll", () => {
      let scrollPos = window.scrollY;
      if (scrollPos >= 100) {
        that.isSticky = true;
      } else {
        that.isSticky = false;
      }
    });
  },
};
</script>
