<template>
    <section class="why-choose-wrap style1 pt-100 pb-100 bg-bunting">
        <div class="container">
            <div class="row gx-5 align-items-center">
                <div class="col-lg-6">
                    <div class="wh-img-wrap">
                        <img src="../../assets/images/why-choose-us/wh-img-1.png" alt="Image">
                        <img class="wh-shape-one bounce" src="../../assets/images/why-choose-us/wh-shape-1.png" alt="Image">
                        <img class="wh-shape-two animationFramesTwo" src="../../assets/images/why-choose-us/wh-shape-2.png" alt="Image">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="wh-content">
                        <div class="content-title style1">
                            <span>Why Choose Us</span>
                            <h2>Fast, Efficient, Online Accounting Services</h2>
                            <p>We are brining a modern approach to a long-standing industry. Using the latest apps and software's, we provide our clients an efficient, agile and reliable accounting service.</p>
                        </div>
                        <div class="feature-item-wrap">
                            <div class="feature-item">
                                <span class="feature-icon">
                                    <i class="flaticon-loan-1"></i>
                                </span>
                                <div class="feature-text">
                                    <h3>Easy Onboarding</h3>
                                    <p>Our Easy Onboarding Process means we can accept clients form all over the country. There is no need for you to take time out of your day traveling to meet us because we can provide all elements of our service remotely, while remaining KYC & AML compliant.</p>
                                </div>
                            </div>
                            <div class="feature-item">
                                <span class="feature-icon">
                                    <i class="flaticon-computer"></i>
                                </span>
                                <div class="feature-text">
                                    <h3>Modern Approach</h3>
                                    <p>We take a modern approach to all areas of our business but most importantly the service we provide to you</p>
                                </div>
                            </div>
                            <div class="feature-item">
                                <span class="feature-icon">
                                    <i class="flaticon-planet-earth"></i>
                                </span>
                                <div class="feature-text">
                                    <h3>Accessible Anywhere</h3>
                                    <p>Using FreeAgent and our Client Portal, you can access your Accounts and Tax returns from anywhere with an internet connection at any time. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'WhyChooseUs'
}
</script>