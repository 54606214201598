<template>
  <footer class="footer-wrap bg-rock">
    <div class="container">
      <div class="row pt-100 pb-75">
        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
          <div class="footer-widget">
            <router-link to="/" class="footer-logo">
              <img src="../../assets/images/logo-title.png" alt="logo" />
            </router-link>
            <div class="social-link">
              <ul class="social-profile list-style style1">
                <li>
                  <a target="_blank" href="https://facebook.com/forestfinancefb/">
                    <i class="ri-facebook-fill"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://instagram.com/forest_finance/">
                    <i class="ri-instagram-fill"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://linkedin.com/company/forest-finance/">
                    <i class="ri-linkedin-fill"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
          <div class="footer-widget">
            <h3 class="footer-widget-title">Useful links</h3>
            <ul class="footer-menu list-style">
              <li>
                <router-link to="/freeagent"> <i class="flaticon-next"></i> FreeAgent</router-link>
              </li>
              <li>
                <a href="https://login.freeagent.com/login/"> <i class="flaticon-next"></i> My FreeAgent</a>
              </li>
              <li>
                <a href="https://myportal.forest-finance.co.uk/signin/"> <i class="flaticon-next"></i> My Portal</a>
              </li>

              <li>
                <a href="https://myportal.forest-finance.co.uk/signin/"> <i class="flaticon-next"></i> My Portal</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
          <div class="footer-widget">
            <h3 class="footer-widget-title">Legal</h3>
            <ul class="footer-menu list-style">
              <li>
                <router-link to="/privacy-policy">
                  <i class="flaticon-next"></i>
                  Business Terms & Conditions
                </router-link>
              </li>
              <li>
                <router-link to="/privacy-policy">
                  <i class="flaticon-next"></i>
                  Privacy Policy
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
          <div class="footer-widget">
            <h3 class="footer-widget-title">About us</h3>
            <ul class="footer-menu list-style">
              <li>
                <router-link to="/contact">
                  <i class="flaticon-next"></i>
                  Contact Us
                </router-link>
              </li>
              <li>
                <a href="https://aat.org.uk/"> <i class="flaticon-next"></i> AAT</a>
              </li>
              <li>
                <p>Company Registration Number: 13884972</p>
              </li>
              <li>
                <p>Licensed by the Association Of Accounting Technicians (AAT)</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright-text">

      <!-- TrustBox widget - Review Collector -->
      <div class="trustpilot-widget" data-locale="en-GB" data-template-id="56278e9abfbbba0bdcd568bc"
        data-businessunit-id="6346aa9efe58d5a3a500483a" data-style-height="52px" data-style-width="100%">
        <a href="https://uk.trustpilot.com/review/forest-finance.co.uk" target="_blank" rel="noopener">Trustpilot</a>
      </div>
      <!-- End TrustBox widget -->
      <p>
        <i class="ri-copyright-line"></i>{{ currentYear }} Forest Finance. All Rights
        Reserved.
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>
