<template>
    <div class="goal-wrap ptb-100">
        <div class="container">
            <div class="row gx-5 align-items-center">
                <div class="col-lg-6">
                    <div class="goal-img-wrap">
                        <img src="../../assets/images/goal/goal-shape-1.png" alt="Image" class="goal-shape-three bounce">
                        <img src="../../assets/images/goal/goal-shape-2.png" alt="Image" class="goal-shape-one">
                        <img src="../../assets/images/goal/goal-shape-3.png" alt="Image" class="goal-shape-two">
                        <img src="../../assets/images/goal/goal-1.jpg" alt="Image">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="goal-content">
                        <div class="content-title style1">
                            <span>GoalSetting</span>
                            <h2>Manage Your Money With Online Banking Solution</h2>
                            <p>There are many variations of passages of Lorem Ipsum amet avoilble but majority have suffered alteration in some form, by injected humur or randomise words which don't sure amet sit dolor quras alto lorem.</p>
                        </div>
                        <ul class="content-feature-list style1 list-style">
                            <li><span><i class="flaticon-tick"></i></span>Learn To Budget</li>
                            <li><span><i class="flaticon-tick"></i></span>Create An Emergency Fund</li>
                            <li><span><i class="flaticon-tick"></i></span>Get Out Of Debt</li>
                            <li><span><i class="flaticon-tick"></i></span>Save And Invest Your Money</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GoalSettings'
}
</script>