<template>
    <div class="contact-wrap pt-100">
        <div class="container">
            <div class="row justify-content-center pb-75">
                <div class="content-title style1 text-center mb-40">
                    <span>Get In Touch</span>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="contact-item">
                        <span class="contact-icon">
                            <i class="flaticon-map"></i>
                        </span>
                        <div class="contact-info">
                            <h3>Our Location</h3>
                            <p>Harlow, Essex, United Kingdom
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="contact-item">
                        <span class="contact-icon">
                            <i class="flaticon-email-2"></i>
                        </span>
                        <div class="contact-info">
                            <h3>Email Us</h3>
                            <a href="mailto:info@forest-finance.co.uk">info@forest-finance.co.uk</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="contact-item">
                        <span class="contact-icon">
                            <i class="flaticon-bubble-chat"></i>
                        </span>
                        <div class="contact-info">
                            <h3>Message Us</h3>
                            <a href="sms:+447415383770">(+44) 7415 383 770</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="contact-form-area ptb-100 bg-albastor">
            <div class="container">
                <div class="row">
                    <div class="col-xl-10 offset-xl-1 col-lg-10 offset-lg-1">
                        <div class="content-title style1 text-center mb-40">
                            <span>Enquire Today</span>
                            <h2>Start Your Journey With Us</h2>
                        </div>
                        <div class="contact-form">
                            <form class="form-wrap" @submit.prevent="handleSubmit" method="POST">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="text" name="firstName" placeholder="Your First Name*"
                                                id="firstName" v-model="firstName" required
                                                data-error="Please enter your name*">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="text" name="lastName" id="lastName" required v-model="lastName"
                                                placeholder="Your Last Name*" data-error="Please enter your last name*">
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="text" name="companyName" placeholder="Your Company Name*"
                                                id="name" v-model="companyName" required
                                                data-error="Please enter your company name*">
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <select name="companyType" id="companyType" v-model="companyType" required
                                                data-error="Please enter your company type*">
                                                <option value="" disabled selected>Please select your company/service
                                                </option>
                                                <option value="sole trader"> Sole Trader</option>
                                                <option value="limited company">Limited Company</option>
                                                <option value="partnership">Partnership</option>
                                                <option value="landlord">Landlord</option>
                                                <option value="other">Other</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="text" name="email" id="email" required v-model="contactEmail"
                                                placeholder="Your Email*" data-error="Please enter your email address*">
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="tel" name="phoneNumber" id="phoneNumber" required
                                                v-model="phoneNumber" placeholder="Your Phone Number*"
                                                data-error="Please enter your phone number*">
                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="form-group v1">
                                            <textarea v-model="message" name="message" id="message"
                                                placeholder="Your description of your business or services you require.."
                                                cols="30" rows="10" required
                                                data-error="Please enter a description of your business or services you require..."></textarea>
                                        </div>
                                    </div>

                                    <div v-if="contactFormSent" class="alert alert-success" role="alert">
                                        <h4 class="alert-heading">Message sent!</h4>
                                        <p>Your message has been sent, we will be in touch as soon as possible!</p>
                                    </div>

                                    <div class="col-md-12 text-center">
                                        <button type="submit" class="btn style1 w-100 d-block"
                                            :disabled="contactFormSent">Enquire now!</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Contact',
    data() {
        return {
            firstName: "",
            lastName: "",
            companyName: "",
            companyType: "",
            contactEmail: "",
            message: "",
            phoneNumber: "",
            contactFormSent: false
        };
    },
    methods: {
        async handleSubmit() {
            let body = {
                firstName: this.firstName,
                lastName: this.lastName,
                companyName: this.companyName,
                companyType: this.companyType,
                contactEmail: this.contactEmail,
                message: this.message,
                phoneNumber: this.phoneNumber
            }

            const requestOptions = {
                method: "POST",
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                    'Accept': 'application/json',
                },
                body: JSON.stringify(body)
            };

            this.contactFormSent = true;

            await fetch("https://forest-finance.azurewebsites.net/customerinquiry", requestOptions);

            this.$gtag.event('contactus');
        }
    }
}
</script>