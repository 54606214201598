<template>
  <div>
    <div class="hero-wrap style3 bg-squeeze">
      <img src="../../assets/images/hero/hero-shape-15.png" alt="Image" class="hero-shape-two" />
      <img src="../../assets/images/hero/hero-shape-14.png" alt="Image" class="hero-shape-three" />
      <img src="../../assets/images/hero/hero-shape-20.png" alt="Image" class="hero-shape-twenty" />
      <img src="../../assets/images/hero/hero-shape-11.png" alt="Image" class="hero-shape-four animationFramesTwo" />
      <div class="hero-slider-one">
        <carousel>
          <Slide v-for="slide in carouselItems" :key="slide.id">
            <div class="hero-slide-item">
              <div class="container">
                <div class="row gx-5 align-items-center">
                  <div class="col-md-6">
                    <div class="hero-content" data-speed="0.10" data-revert="true">
                      <span>{{ slide.subtitle }}</span>
                      <h1>{{ slide.heading }}</h1>
                      <p>{{ slide.desc }}</p>
                      <div class="hero-btn">
                        <router-link to="/contact" class="btn style1">Get Started</router-link>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="hero-img-wrap">
                      <img src="../../assets/images/hero/hero-shape-13.png" alt="Image"
                        class="hero-shape-five bounce" />
                      <img src="../../assets/images/hero/hero-shape-13.png" alt="Image"
                        class="hero-shape-six moveHorizontal" />
                      <img :src="slide.image" alt="Image" class="hero-img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slide>

          <template #addons>
            <Pagination />
          </template>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Slide, Pagination } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "MainBanner",
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data: () => ({
    isPopup: false,
    carouselItems: [
      {
        id: 1,
        subtitle: "Cost Efficient. Scaleable. Connected.",
        heading: "Advanced Cloud Accounting Solutions for your business.",
        desc: "Offering complete, modern, accounting solutions to all businesses and sole traders. No more paper and pen, get MTD compliant today.",
        image: require("../../assets/images/hero/hero-slide-1.svg"),
      },
      {
        id: 2,
        subtitle: "Affordable. Simple. Compliant.",
        heading: "Self-Employed, Landlord or a Sole Trader?",
        desc: "Get ready for Making Tax Digital for Income Tax and Self Assessment with one of our packages. The time to get compliant is now.",
        image: require("../../assets/images/hero/hero-slide-2.png"),
      },
      {
        id: 3,
        subtitle: "Saleable. Efficient. Connected.",
        heading: "Company Accounting & Business Support Packages",
        desc: "We offer a range of company accounting services including accounts and tax filings, payroll and Vat. You run your business best, so let us give you back your time by doing what we do best.",
        image: require("../../assets/images/hero/hero-slide-3.png"),
      },
      {
        id: 4,
        subtitle: "Diverse. Modern. Insight.",
        heading: "Power BI & Other Consulting",
        desc: "We also offer consulting services for Microsoft's Power BI Platform and other reporting tools, enabling you to get valuable insights for your business.",
        image: require("../../assets/images/hero/hero-slide-1.svg"),
      },
    ],
  }),
  methods: {
    isPopupMethod(isPopup) {
      return (this.isPopup = !isPopup);
    },
  },
});
</script>
