<template>
    <div class="feature-wrap pb-75">
        <div class="container">
            <div class="section-title style1 text-center mb-40">
                <span>Sign up today in three easy steps</span>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="feature-card style3">
                        <div class="feature-info">
                            <div class="feature-title">
                                <span class="contact">
                                    <i class="flaticon-email-2"></i>
                                </span>
                                <h3>Contact us</h3>
                            </div>
                            <p>Fill out our contact form, make sure to include as much information as you can. Once
                                complete, one of our Accredited Accountants will be in touch to schedule a consultation.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="feature-card style3">
                        <div class="feature-info">
                            <div class="feature-title">
                                <span class="contact">
                                    <i class="flaticon-network"></i>
                                </span>
                                <h3>Consultation</h3>
                            </div>
                            <p>This will be around half an hour to fully gauge your wants and needs. After we've agreed
                                the services that we'll be providing to you, a Letter of Engagement will be issued for
                                signing.</p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="feature-card style3">
                        <div class="feature-info">
                            <div class="feature-title">
                                <span class="contact">
                                    <i class="flaticon-support"></i>
                                </span>
                                <h3>Onboarding</h3>
                            </div>
                            <p>Your accountant will take you through the onboarding process, ensuring everything is
                                fully compliant and you have a clear picture of what services will be provided when.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Feature'
}
</script>