<template>
    <div class="counter-wrap">
        <div class="container">
            <div class="counter-card-wrap">
                <div class="counter-card">
                    <div class="counter-text">
                        <div class="counter-num">
                            <span>5430</span>
                        </div>
                        <p>Happy Customers</p>
                    </div>
                </div>
                <div class="counter-card">
                    <div class="counter-text">
                        <div class="counter-num">
                            <span>25</span>
                        </div>
                        <p>Years in Banking</p>
                    </div>
                </div>
                <div class="counter-card">
                    <div class="counter-text">
                        <div class="counter-num">
                            <span>2597</span>
                        </div>
                        <p>Our Branches</p>
                    </div>
                </div>
                <div class="counter-card">
                    <div class="counter-text">
                        <div class="counter-num">
                            <span>40467</span>
                        </div>
                        <p>Successful Projects</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Counter'
}
</script>