<template>
    <div class="shopping-wrap ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-12 order-lg-1 order-2">
                    <div class="shopping-content">
                        <img src="../../assets/images/shopping/shopping-shape-1.png" alt="Image"
                            class="shopping-shape-one moveHorizontal">
                        <img src="../../assets/images/shopping/shopping-shape-2.png" alt="Image"
                            class="shopping-shape-two bounce">
                        <div class="content-title style1 ">
                            <span>Easy Onboarding</span>
                            <h2>We Can Accept New Clients From All Over The UK</h2>
                            <p>Becoming a client couldn't be easier. With just three steps, and from wherever you are,
                                we can create a proposal that works for you. Our 'No-need to meet' on boarding process
                                gives our clients their valuable time back because there is no need to meet in person.
                                Our AML & KYC checks can all be done within 5 minutes with a smartphone, passport and
                                proof of address. </p>
                        </div>
                        <ul class="content-feature-list list-style">
                            <li><i class="ri-check-double-line"></i>Fill out our contact form & our team will arrange a consultation</li>
                            <li><i class="ri-check-double-line"></i>Proposal produced by one of our Accountants</li>
                            <li><i class="ri-check-double-line"></i>Complete your ID & Proof of Address Check</li>
                        </ul>
                        <router-link to="/contactus" class="btn style1">Get in touch</router-link>
                    </div>
                </div>
                <div class="col-lg-6 col-12 order-lg-2 order-1">
                    <div class="shopping-img-wrap">
                        <img src="../../assets/images/shopping/shopping-1.png" alt="Image">
                        <img src="../../assets/images/shopping/shopping-shape-3.png" alt="Image"
                            class="shopping-shape-three">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OnlineShopping'
}
</script>